/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import 'quill/dist/quill.snow.css';

/* leaflet maps */

@import 'leaflet/dist/leaflet.css';



// Custom marker icon fix
.leaflet-default-icon-path {
    background-image: url(leaflet/dist/images/marker-icon.png);
  }