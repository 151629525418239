/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


ion-content {
    --overflow: scroll;
    --touch-action: pan-y;
  }
    // fuse-loading-bar  {
    //   :host{
    //     mat-progress-bar{
        
    //     }
    //   }
    // }

  .theme-default .light .mat-mdc-progress-bar, .theme-default.light .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color:#162711 !important;  
    // #529401 !important;
    // 5d8f2191
    --mdc-linear-progress-track-color: #dfc7a76e  !important;
  }


.theme-default .light .mat-mdc-raised-button.mat-primary,
 .theme-default.light .mat-mdc-raised-button.mat-primary,
 .theme-default .light .mat-mdc-button.mat-primary,
  .theme-default.light .mat-mdc-button.mat-primary,
   .theme-default.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-protected-button-container-color: #39592f  !important;
  --mdc-filled-button-container-color: #39592f  !important;

  --mdc-text-button-label-text-color: #162711;
  --mat-text-button-state-layer-color: #162711;
  --mat-text-button-ripple-color: rgb(89 197 55 / 10%);

}

.mat-step-icon-selected {
  background-color: #39592f !important;
}



.custom-manage-team-dialog-panel{
  .mat-mdc-dialog-surface, .mdc-dialog__surface{
    padding: 12px !important;

  }
  --mat-dialog-with-actions-content-padding: 24px 5px;
}
  @media (max-width: 767px) {
    .mdc-evolution-chip__text-label, .mat-mdc-chip-action-label {
      width: 90px !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
    }
  }



  app-employee-metrics{
    .mat-mdc-tab-body-content{
      padding: 0!important ;
  }
  }



  // .mat-mdc-form-field {
  //   input, 
  //   textarea {
  //     font-size: 16px !important;
  //   }
    
  //   .mat-mdc-form-field-label {
  //     font-size: 16px !important;
  //   }
  // }



  html, body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    -webkit-font-smoothing: antialiased;
    -font-smoothing: antialiased;
    --mat-dialog-container-small-max-width : 90vw !important;
    --ion-background-color:#f1f5f9;

  }


  @media (max-width: 599px) {
    .cdk-overlay-pane.mat-mdc-dialog-panel {
      min-width: 80vw !important;
    }
}

 
  
  .app-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  // Android specific styles
  // .md {
  //   input, textarea {
  //     margin-bottom: 1rem; /* Give some space at the bottom of inputs */
  //   }
  // }
  
  // iOS specific styles
  // .ios {
  //   input, textarea {
  //     /* Prevent iOS zoom on focus */
  //     font-size: 16px !important;
  //   }
  // }

  // @media screen and (-webkit-min-device-pixel-ratio: 0) { 
  //   select,
  //   textarea,
  //   input {
  //     font-size: 16px !important;
  //   }
  // }

  .mat-mdc-dialog-container {
    margin: auto !important;
    // width: 98% !important;
  }


  :root {
    --viewport-height: 100vh;
  }
  
  body {
    height: var(--viewport-height);
    overflow-y: auto;
    position: fixed;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  
  .ios body {
    /* Ensures content is pushed above keyboard */
    padding-bottom: 0;
  }


  * {
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
  }
  
  input, select, textarea {
    touch-action: manipulation;
  }



  button[mat-icon-button] {
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    cursor: pointer;
    
    // Increase touch target
    min-width: 44px;
    min-height: 44px;
    
    // Prevent text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    
    // Fix for iOS
    position: relative;
    z-index: 1;

    &:active {
      opacity: 0.7;
    }
    
    &:focus {
      outline: none;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

}

// Add padding around icon for larger touch area
// .mat-icon {
//     padding: 8px;
// }


mat-expansion-panel {
  .mat-expansion-panel-content {
    position: relative;
    z-index: 1;
  }
}

// The message field container might be overlapping
.fuse-mat-dense {
  position: relative;
  z-index: 0;
}
fuse-confirmation-dialog{
  .mdc-button{
    font-size: 12px !important;
  }
}


.mat-mdc-form-field-icon-suffix {
  padding-top: 0 !important;
  padding: auto;    
  align-items: center;
  min-height: 48px;
}



.mat-mdc-form-field.mat-form-field-appearance-fill.fuse-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field.mat-form-field-appearance-fill.fuse-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-prefix, .mat-mdc-form-field.mat-form-field-appearance-fill.fuse-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix, .mat-mdc-form-field.mat-form-field-appearance-fill.fuse-mat-dense:has(textarea.mat-mdc-input-element) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-text-suffix {
  padding-top: 0 !important;
}


app-observation-details{
  .mdc-tab {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  
  .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container {
    margin: 0 6px !important;
  }
}

app-participants-selection{
  .mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label{
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
    width: 100% !important;
  }

  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    flex-basis: 100% !important;;
    width: 100% !important ;
    flex-direction: row !important;;
    display: flex !important;;
  }
 
}


.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
  padding:  8px !important;
} 
.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content{
  padding: 10px 6px !important;
}

.dialog-actions{
  padding: 0!important;
}
.dialog-header{
  padding: 8px 4px !important;
}

app-observation-details{
  .mat-mdc-tab-body-content{
    padding: 0 !important;
    }
}


app-audit-details{
  .mat-mdc-tab-label-container{
    margin : 0 !important;
  }
}


app-standard-details{
  .mat-mdc-card-header-text{
      @apply w-full
  }
  }
  